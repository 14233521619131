import * as React from "react";
import aNewImg from "src/images/community/a-new-bg.jpg";
import aNewThumb from "src/images/community/Shalamar Carliss.jpg";
import day1Img from "src/images/community/day1.jpg";
import covidImg from "src/images/community/life-under-covid.jpg";
import muralMapImg from "src/images/community/mural-map.jpg";
import wendyImg from "src/images/community/wendy.jpg";
import wendyGlassesImg from "src/images/community/wendy-glasses.jpg";
import wendyLegacyThumb from "src/images/community/wendy-legacy-campaign-square.jpg";
import wendyLegacy from "src/images/community/wendy-legacy-campaign.jpg";
import nextChapterForEOCPImg from "src/images/community/next-chapter-for-eocp.jpg";
import turningImg from "src/images/community/a-turning-point.jpg";
import famImg from "src/images/community/family-matters-fam2.jpg";
import staffImg from "src/images/community/eocp-staff.jpg";
import servingImg from "src/images/community/serving0.jpg";
import findingImg from "src/images/community/finding0.jpg";
import brighterFutureImg from "src/images/community/brighter-future.png";
import movingInAndUpImg from "src/images/community/moving-in-and-up.jpg";
import celebrateOurWinsImg from "src/images/community/celebrate-our-wins.jpg";
/**
 * List all blog posts with relevant metadata to avoid circular dependency chains
 */
const posts = [
  {
    slug: "celebrate-our-wins",
    title: "Celebrate our wins with us",
    date: "Nov 16, 2023",
    blurb: `During this holiday season of giving, remember EOCP, a local, community-based organization that is making a difference in East Oakland. We thank you for your support. `,
    thumb: celebrateOurWinsImg,
  },
  {
    slug: "moving-in-and-up",
    title: "Moving in and Up!",
    date: "Oct 25, 2023",
    blurb: `It is our intent to provide a safe and secure environment for everyone residing at our facility while they work towards well-being and a permanent housing placement.`,
    thumb: movingInAndUpImg,
  },
  {
    slug: "brighter-future",
    title: "A Brighter Future is Possible",
    date: "Sep 28, 2023",
    blurb: `At EOCP we honor our clients' personal stories and we believe that effective, dignified care begins with genuinely understanding a person as who they are, not just what happened to them.`,
    thumb: brighterFutureImg,
  },
  {
    slug: "finding-care-community",
    title: "Finding Care and Community",
    date: "Aug 23, 2023",
    blurb:
      "At EOCP, we know that each unhoused person, no matter their struggles with mental health or substance use, carries a unique and complex set of life stories with them.",
    img: findingImg,
  },
  {
    slug: "serving-more-people",
    title: "Serving More People through Collaboration and Partnership",
    date: "July 18, 2023",
    blurb:
      "At EOCP we build personalized, trusting, and authentic relationships with people experiencing homelessness, and walk alongside them as they grow into a life of self-reliance.",
    img: servingImg,
  },
  {
    slug: "meet-new-leaders",
    title: "Meet New Leaders Making Their Mark at EOCP",
    date: "June 27, 2023",
    blurb: `Myeeka Calhoun and Carmen Hidalgo both work at EOCP’s Crossroads shelter.  They joined the organization a year ago and have brought a renewed spirit to assisting unhoused individuals and families.   They share their perspectives on EOCP and why working in homeless services is important to them.`,
  },
  {
    slug: "eocp-new-leaders-making-their-mark",
    title: "New Leaders Making their Mark at EOCP",
    date: "June 6, 2023",
    img: staffImg,
    blurb: `EOCP's staff are the anchor of our tireless work in supporting people to break out of cycles of homelessness and transform their lives.`,
  },
  {
    slug: "family-matters-is-a-place-of-possibility",
    title: "Family Matters is a place of possibility",
    date: "May 2, 2023",
    img: famImg,
    blurb: `EOCP's Family Matters shelter is a space that creates beautiful possibility: Since its door opened in June 2020, families with children arrive onsite ready to make a change in their lives, and cross the threshold out of homelessness.`,
  },
  {
    slug: "a-turning-point",
    title: "A turning point for unhoused families",
    date: "Apr 4, 2023",
    img: turningImg,
    blurb:
      "2023 has ushered in a big season of change at EOCP: We’ve welcomed interim executive leadership and hired key staff at Crossroads, our emergency shelter located in the heart of East Oakland. Our commitment to persistent, comprehensive care for families experiencing homelessness continues.",
  },
  {
    slug: "next-chapter-for-eocp",
    title: "The next chapter for EOCP",
    date: "Dec 15, 2022",
    imgBackgroundPosition: "50% 50%",
    img: nextChapterForEOCPImg,
    blurb: (
      <>
        Joining EOCP as Interim Executive Director—and learning about its
        history, strengths, and vision—has been a bittersweet gift. I am moved
        by the heartfelt stories of staff, colleagues, and partners who
        collaborated with EOCP’s longtime leader, Wendy Jackson, across her 26
        year tenure, and the reflection and celebration they offer about the
        incredible impact of Wendy’s leadership. One thing is clear: Wendy’s
        authenticity, dedication, and ethic of holistic care for EOCP’s clients
        has shaped all aspects of our work today.
      </>
    ),
  },
  {
    slug: "wendy-jackson-legacy-campaign",
    title: "Celebrate Wendy's Legacy",
    date: "Dec 5, 2022",
    imgBackgroundPosition: "50% 50%",
    img: wendyLegacy,
    thumb: wendyLegacyThumb,
    blurb: (
      <>
        As we near the end of the year, we are struck with gratitude and grief
        for our late Executive Director, the Wonderful Wendy Jackson, who passed
        away in October 2O22 after a hard-fought battle with illness. Wendy, who
        was preparing for her retirement, served 26 phenomenal years with EOCP.
        Wendy’s leadership at EOCP was profound, and she will be deeply missed.
        In partnership, in service, and behind the scenes, she led our
        organization’s critical work of nurturing the dreams of people
        experiencing homelessness—and she did so with unwavering integrity,
        heartfelt commitment, and a deep belief in community-based
        collaboration.
      </>
    ),
  },
  {
    slug: "remembering-wendy",
    title: "Remembering Wendy Jackson and EOCP Leadership News",
    date: "Nov 16, 2022",
    imgBackgroundPosition: "50% 50%",
    img: null,
    thumb: wendyGlassesImg,
    blurb: (
      <>
        On the morning of October 25th, we learned that our wonderful Executive
        Director, Wendy Jackson, had passed away. She was an incredible leader
        and cared so deeply for the work of EOCP and this community. We will
        miss her terribly.
      </>
    ),
  },
  {
    slug: "wendys-retirement",
    title: "Announcing Wendy Jackson’s Retirement from EOCP",
    date: "Sep 27, 2022",
    imgBackgroundPosition: "50% 50%",
    img: null,
    thumb: wendyImg,
    blurb: (
      <>
        Please join us in thanking, celebrating, and honoring the Wonderful
        Wendy Jackson for her service.
      </>
    ),
  },
  {
    slug: "staff-from-east-oakland-grow-as-leaders",
    title: "At EOCP, Staff From East Oakland Grow As Leaders",
    date: "May 7, 2022",
    imgBackgroundPosition: "50% 15%",
    img: muralMapImg,
    blurb: (
      <>
        While our work has expanded to serve across Alameda County, we’ve been
        steadfastly committed to our home neighborhood, East Oakland. This
        commitment is brought to life every day by our dedicated team — many of
        whom are lifelong East Oaklanders. Their work and their presence
        demonstrates: EOCP is committed to who we say we are committed to, not
        only in the people we serve, but also in the people whose careers we
        invest in and grow.
      </>
    ),
  },
  {
    slug: "growth-resilience-covid",
    title: "Growth and Resilience in the Time of COVID-19",
    date: "December 3, 2021",
    imgBackgroundPosition: "50% 15%",
    img: covidImg,
    blurb: (
      <>
        The COVID-19 pandemic has been one of the biggest challenges for our
        entire world. For people experiencing homelessness in Oakland and
        Alameda County, it was an especially heavy hit. At EOCP, we maneuvered
        with the flexibility, creativity, and deep commitment that we&rsquo;ve
        embodied from day one of doing this work. Our team stepped up to adapt
        to the crisis, strengthen our teamwork, and keep following through. Read
        more about EOCP&rsquo;s resilience in responding to COVID-19.
      </>
    ),
  },
  {
    slug: "a-new-chapter",
    title: "A New Chapter for Two Families",
    date: "November 30, 2021",
    imgBackgroundPosition: "50% 15%",
    img: aNewImg,
    thumb: aNewThumb,
    blurb: (
      <>
        We’re sharing stories of success and growth for two families. With the
        support of EOCP, each of these families charted a pathway out of
        homelessness for themselves and their children. With resilience and
        commitment, each of these families is now on the road to well-being, and
        a new chapter in their lives.
      </>
    ),
  },
  {
    slug: "day-1-fund",
    title: "EOCP Receives Day 1 Families Fund Grant to End Homelessness",
    date: "December 9, 2020",
    imgBackgroundPosition: "90% 50%",
    img: day1Img,
    blurb: (
      <>
        EOCP was selected to receive a $2.5 million grant from the Bezos Day 1
        Families Fund, which issues annual leadership awards to organizations
        and civic groups doing compassionate, needle-moving work to provide
        shelter and hunger support to address the immediate needs of young
        families.
      </>
    ),
  },
];

export default posts;

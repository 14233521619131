import Typography from "@mui/material/Typography";
import * as React from "react";

export default function Paragraph({ sx = {}, ...props }) {
  return (
    <Typography
      component="p"
      variant="body1"
      sx={{ mb: 2, ...sx }}
      {...props}
    />
  );
}

import Typography from "@mui/material/Typography";
import { BLOG_WIDTH } from "constants";
import posts from "posts";
import * as React from "react";
import { Helmet } from "react-helmet";
import Container from "src/components/Container";
import ImageHeaderUnit from "src/components/ImageHeaderUnit";
import Layout from "src/components/Layout";

export default function BlogLayout({ children, slug }) {
  const metadata = posts.find((x) => x.slug === slug);
  const { date: subtitle, title, img, imgBackgroundPosition } = metadata;
  const maxTitleWidth = "12em";
  return (
    <Layout>
      <Helmet>
        <title>Community Stories - {metadata.title}</title>
      </Helmet>
      {metadata.img ? (
        <ImageHeaderUnit
          subtitle={subtitle}
          title={title}
          img={img}
          backgroundPosition={imgBackgroundPosition}
          maxTitleWidth={maxTitleWidth}
          containerProps={{ sx: { maxWidth: { xs: BLOG_WIDTH } } }}
        />
      ) : (
        <Container sx={{ pt: 2, maxWidth: { xs: BLOG_WIDTH } }}>
          <Typography
            color="secondary.light"
            variant="overline"
            display="block"
            py={1}
          >
            {subtitle}
          </Typography>
          <Typography
            color="secondary.light"
            variant="headline"
            sx={{
              paddingTop: 1,
            }}
          >
            {title}
          </Typography>
        </Container>
      )}
      <Container sx={{ maxWidth: { xs: BLOG_WIDTH } }}>{children}</Container>
    </Layout>
  );
}

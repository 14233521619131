import Box from "@mui/material/Box";
import { Link } from "gatsby-theme-material-ui";
import * as React from "react";
import BlogLayout from "src/components/BlogLayout";
import Paragraph from "src/components/Paragraph";
export default function WendyJacksonLegacyCampaignPage() {
  return (
    <BlogLayout slug="wendy-jackson-legacy-campaign">
      <Paragraph>
        <strong>Dear Friend:&nbsp;</strong>
      </Paragraph>

      <Paragraph>
        As we near the end of the year, we are struck with gratitude and grief
        for our late Executive Director, the Wonderful Wendy Jackson, who passed
        away in October 2022 after a hard-fought battle with illness. Wendy, who
        was preparing for her retirement, served 26 phenomenal years with EOCP.{" "}
        <strong>
          Wendy’s leadership at EOCP was profound, and she will be deeply
          missed.
        </strong>{" "}
        In partnership, in service, and behind the scenes, she led our
        organization’s critical work of nurturing the dreams of people
        experiencing homelessness—and she did so with unwavering integrity,
        heartfelt commitment, and a deep belief in{" "}
        <strong>community-based collaboration.&nbsp;</strong>
      </Paragraph>

      <Paragraph>
        Today, we begin to honor the vastness of Wendy’s impact by establishing
        the <strong>Wendy Jackson Legacy Fund.</strong> With this fund, we{" "}
        <strong>
          celebrate and acknowledge Wendy’s legacy and her commitment
        </strong>{" "}
        to the people of Oakland and Alameda County, and ensure that her memory
        lives on. Through Wendy’s leadership, EOCP reached{" "}
        <strong>tremendous milestones, </strong>including:
      </Paragraph>

      <Box
        sx={{
          backgroundColor: "green.50",
        }}
        p={3}
        pb={1}
        my={3}
      >
        <Paragraph variant="body2" component="ul">
          <li>
            Developing a strong, organizational vision for holistic care that
            centers not just on housing, but each client’s well-being and
            ability to thrive in life.
          </li>
          <li>
            Growing programming from a single location to a $10M+, multi-site
            community resource with residential and rapid rehousing initiatives,
            medical respite care, and focused offerings for Veterans and
            families—such as the recently opened Family Matters shelter.
          </li>
          <li>
            Fostering instrumental relationships with city, regional, and
            national partners and organizations to sustain programs on an
            ongoing basis.
          </li>
          <li>
            Building Crossroads, a state-of-the-art, environmentally green
            shelter - the only facility that has been built from the ground up
            in the heart of the Oakland community that continues to serve our
            community with dignity.
          </li>
          <li>
            Cultivating and hiring a robust team of passionate staff, with many
            who hail from Oakland and keep EOCP’s work rooted in place.
          </li>
        </Paragraph>
      </Box>

      <Paragraph>
        None of this transformative work would have been possible without Wendy.{" "}
        <strong>
          Her vision for change guided this journey, but as Wendy herself often
          reminded us, we reached each of these milestones because of YOUR
          steadfast support and collaboration.{" "}
        </strong>
        We will deeply miss Wendy’s daily guidance and presence, and keep her
        vision close at hand.&nbsp; As EOCP transitions into its next chapter,
        we will continue to imbue the work with her legacy of community-led,
        dignified, and trusted care at EOCP.
      </Paragraph>

      <Paragraph>
        <strong>
          Please join us in recognizing Wendy, her life, and her memory by
          donating to the Wendy Jackson Legacy Fund today.{" "}
        </strong>
        By becoming a founding donor, you will be helping to sustain a strong
        foundation to continue and expand EOCP’s work sheltering and supporting
        members of our community currently experiencing homelessness.&nbsp;
      </Paragraph>

      <Paragraph>
        <strong>
          Contribute to the Wendy Jackson Legacy Fund{" "}
          <Link
            href="https://www.paypal.com/donate/?hosted_button_id=G93HRCX7NCCL6"
            target="_blank"
            rel="noreferrer"
          >
            by donating online
          </Link>
        </strong>{" "}
        or send a check in the enclosed envelope. Please don’t wait—we need you
        to help us build this anchor for the tireless work that EOCP does to
        support people fighting homelessness, every day.
      </Paragraph>

      <Paragraph>
        <br />
        In community,
        <br />
        &nbsp;
      </Paragraph>

      <Paragraph>
        <strong>Kevin Bremond</strong>
        <br />
        <em>
          Board Chair
          <br />
          EOCP Board of Directors
        </em>
        <br />
        &nbsp;
      </Paragraph>
    </BlogLayout>
  );
}

import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "src/components/Container";
import Color from "color";

export default function ImageHeaderUnit({
  subtitle,
  img, // optional
  title,
  maxTitleWidth = 1000,
  backgroundPosition = "center center",
  containerProps = {},
}) {
  const innerTypography = (
    <>
      <Typography
        color="secondary.light"
        variant="overline"
        display="block"
        py={1}
      >
        {subtitle}
      </Typography>
      <Typography
        color="secondary.light"
        variant="headline"
        sx={{
          maxWidth: maxTitleWidth,
          paddingTop: 1,
          display: img
            ? {
                xs: "block",
                sm: "none",
              }
            : "block",
        }}
      >
        {title}
      </Typography>
    </>
  );

  if (!img) {
    return (
      <Container {...containerProps}>
        <Box py={5}>{innerTypography}</Box>
      </Container>
    );
  }

  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${img})`,
          backgroundSize: "cover",
          backgroundPosition,
          paddingBottom: {
            xs: "60%",
            sm: "400px",
          },
          position: "relative",
        }}
        mb={2}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            paddingBottom: (theme) => theme.spacing(3),
            display: {
              xs: "none",
              sm: "flex",
            },
            background: (theme) =>
              Color(theme.palette.green[900]).alpha(0.7).string(),
          }}
          alignItems="flex-end"
        >
          <Container {...containerProps}>
            <Typography
              color="white"
              variant="headline"
              component="h1"
              sx={{
                maxWidth: maxTitleWidth,
              }}
            >
              {title}
            </Typography>
          </Container>
        </Box>
      </Box>
      <Container {...containerProps}>
        <Box pb={3}>{innerTypography}</Box>
      </Container>
    </>
  );
}
